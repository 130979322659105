import { apiClient } from "./apiClient";
import { DevGame, Game, Match, User } from "./models";

export async function getUserMe() {
    const response = await apiClient.get<User>("/user/me");

    return response.data;
}

export async function createUserMe(firebaseToken: string, username: string) {
    const response = await apiClient.post("/user/me", {
        firebase_token: firebaseToken,
        username
    });

    return response.data;
}

export async function getGames(filters: any = {}) {
    const response = await apiClient.get<Game[]>("/game", {params: filters});

    return response.data;
}

export async function updateGame(id: string, url: string, logo_url: string) {
    const response = await apiClient.patch<Game>("/dev/game/" + id, {url, logo_url});

    return response.data;
}

export async function createGame(name: string) {
    const response = await apiClient.post<DevGame>("/dev/game", {
        name
    });
    
    return response.data;
}

export async function getGame(id: string) {
    const response = await apiClient.get<Game>("/game/" + id);

    return response.data;
}

export async function getDevGames() {
    const response = await apiClient.get<DevGame[]>("/dev/game");

    return response.data;
}

export async function getDevGame(id: string) {
    const response = await apiClient.get<DevGame>("/dev/game/" + id);

    return response.data;
}

export async function getMatches(gameId: string) {
    const response = await apiClient.get<Match[]>(`/game/${gameId}/match`);

    return response.data;
}

export async function getMatch(gameId: string, id: string) {
    const response = await apiClient.get<Match>(`/game/${gameId}/match/${id}`);

    return response.data;
}

export async function createMatch(gameId: string, name: string) {
    const response = await apiClient.post<Match>(`/game/${gameId}/match`, {game_id: gameId, name: name});

    return response.data
}