import App from "./App";
import { AuthenticationProvider } from "./stores/authentication";
import { BrowserRouter } from "react-router-dom";
import { NotificationProvider } from "./stores/notification";


export default function AppWrapper() {
    return (
        <BrowserRouter>
            <AuthenticationProvider>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </AuthenticationProvider>
        </BrowserRouter>
    );
}