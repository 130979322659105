import { useEffect, useState } from "react";
import { DevGame } from "../api/models";
import { getDevGames } from "../api/requests";
import GamesRow from "../components/GamesRow";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {LinkContainer} from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function DeveloperRoute() {
    const [games, setGames] = useState<DevGame[] | null>(null);

    async function initGames() {
        const games = await getDevGames();

        setGames(games);
    }
    useEffect(() => {
        initGames();
    }, []);

    function canCreateGame(): boolean {
        // Only one game allowed per member
        return games != null && games.length == 0;
    }

    return (
        <>
            <h2>Your games</h2>
            {games ? (
                <GamesRow games={games} link={game => "/dev/game/" + game.id} />
            ) : (
                <div>No games</div>
            )}
            <Row className="justify-content-end mt-2">
                <Col xs="auto">
                    <OverlayTrigger
                        overlay={!canCreateGame() ? <Tooltip id="cant-create-game">During the beta, a member can only have one game on AirMeeple</Tooltip> : <span></span>}
                    >
                        <div>
                            <LinkContainer to={"/dev/game/create"}>
                                <Button disabled={!canCreateGame()}>Create</Button>
                            </LinkContainer>
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
        </>
    );
}