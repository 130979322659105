import { LinkContainer } from "react-router-bootstrap";
import { Game } from "../api/models";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export interface GameCardProps {
    game: Game;
    link: string;
}

export default function GameCard({game, link}: GameCardProps) {
    return (
        <LinkContainer to={link}>
            <a className="text-reset" href={link}>
                <div className="game-card" style={{backgroundImage: `url("${game.logo_url}")`}}>
                    <div className="game-card-content">
                        <Row className="h-100">
                            <Col className="align-self-end">
                                <h4 style={{marginBottom: "0"}}>{game.name}</h4>
                            </Col>
                        </Row>
                    </div>
                </div>
            </a>
        </LinkContainer>
    );
}