import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from "react";
import { AuthenticationContext } from "../stores/authentication";
import {useHistory} from "react-router";

const schema = yup.object().shape({
    username: yup.string().min(3).max(30).required(),
    email: yup.string().email().required(),
    password: yup.string().min(6).max(255).required(),
});

export default function RegisterRoute() {
    const {register, handleSubmit, formState: {errors}, setError} = useForm({resolver: yupResolver(schema)});

    const {register: registerUser, signInWithGoogle} = useContext(AuthenticationContext);
    const history = useHistory();

    const submit = handleSubmit(async data => {
        try {
            await registerUser(data.username, data.email, data.password);

            history.push("/");
        } catch (e) {
            if (e.code == "auth/email-already-in-use") {
                setError("email", {type: "email-in-use", message: "Email already in use"});
            }
        }
    });

    function onRegisterWithGoogleClick() {
        signInWithGoogle();
    }

    return (
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
                <Card>
                    <Card.Body>
                        <Card.Title>Register</Card.Title>
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <Button onClick={onRegisterWithGoogleClick}>
                                    Register with Google
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        <Form onSubmit={submit}>
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control {...register("username")} type="text" isInvalid={!!errors.username} />
                                <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control {...register("email")} type="text" isInvalid={!!errors.email} />
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control {...register("password")} type="password" isInvalid={!!errors.password} />
                                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">Join</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

