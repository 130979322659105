import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from "react";
import { AuthenticationContext } from "../stores/authentication";
import {useHistory, useLocation} from "react-router";

const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
});


export default function SignInRoute() {
    const {register, handleSubmit, formState: {errors}, setError} = useForm({resolver: yupResolver(schema)});

    const {signIn, signInWithGoogle} = useContext(AuthenticationContext);
    const history = useHistory();
    const next = new URLSearchParams(useLocation().search).get("next");

    const submit = handleSubmit(async data => {
        try {
            await signIn(data.email, data.password);

            history.push(next ? next : "/");
        } catch (e) {
            if (e.code == "auth/invalid-email" || e.code == "auth/user-not-found" || e.code == "auth/wrong-password") {
                setError("email", {type: "wrong", message: "Invalid username or password"});
            }
        }
    });

    function onSignInWithGoogleClick() {
        signInWithGoogle();
    }

    return (
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
                <Card>
                    <Card.Body>
                        <Card.Title>Sign in</Card.Title>
                        <Form onSubmit={submit}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control {...register("email")} type="text" isInvalid={!!errors.email} />
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control {...register("password")} type="password" isInvalid={!!errors.password} />
                                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">Sign in</Button>
                        </Form>
                        <hr />
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <Button onClick={onSignInWithGoogleClick}>
                                    Register with Google
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}