import { useContext } from "react";
import { NotificationContext } from "../stores/notification";
import "./NotificationSystem.scss";
import Alert from "react-bootstrap/Alert";

export default function NotificationSystem() {
    const {notifications} = useContext(NotificationContext);

    return (
        <div style={{position: "absolute", bottom: 0, left: 0, width: "20rem"}} className="ml-3 mr-3">
            {notifications.map(notification => (
                <Alert key={notification.id} variant={notification.type}>
                    {notification.message}
                <div style={{
                    height: "4px",
                    width: "50px",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    marginBottom: "-0.79rem",   
                    marginRight: "-1.25rem",
                    marginLeft: "-1.25rem",
                    marginTop: "0.6rem",
                    borderRadius: "0 0 0 0.25rem",
                    animationName: "shrink",
                    animationDuration: (notification.toFlushTime - notification.createdTime) + "ms",
                    animationTimingFunction: "linear",
                }} />
                </Alert>
            ))}
        </div>
    );
}