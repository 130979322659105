import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/esm/Card";


export default function HomeRoute() {
    return (
        <Row className="justify-content-center">
            <Col xs={12} md={9}>
                <Card>
                    <Card.Body>
                        <Card.Title className="mb-1">Welcome to the Beta of AirMeeple!</Card.Title>
                        <div className="text-muted mb-2"><small>27/01/2019</small></div>
                        <h6>What is AirMeeple?</h6>
                        <p>
                            AirMeeple is an online platform to play board games with players around the world!
                            Its main difference compared to other existing platforms such as BGA or Tabletopia
                            is that it's open: anyone can code a game and make it available of AirMeeple.
                        </p>
                        <p>
                            I started developing it after <a href="https://swordsandravens.net/">Swords and Ravens</a>,
                            when I realized that it could be possible to develop a library to make it easy to develop
                            online turn-based games (this became Ravens) and, after that, a platform that can
                            host Ravens game.
                        </p>
                        <p>
                            AirMeeple is a beta at the moment, and offers only basic features. The only available
                            game for the moment is A Game of Thrones: the board game - Second Edition.
                            In the future, I plan on adding ranked games, moderation tools, a karma system and
                            more. Any game that is uploaded to AirMeeple will profit from these features without
                            having to implement them from scratch. 
                        </p>
                        <h6>Interested in developing a game?</h6>
                        <p>
                            Games on AirMeeple are developped using the <a href="https://ravens.dev/">Ravens</a> library.
                        </p>
                        <p>
                            If you are interested, you can already start to learn how to develop your game by reading
                            the documentation of <a href="https://ravens.dev/">Ravens</a>. If you have questions or
                            feedback about Ravens, don't hesitate to join the Discord of AirMeeple and shoot me
                            (@longwelwind) message in the <i>#ravens</i> channel!
                        </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}