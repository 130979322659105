import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { createGame } from "../api/requests";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
    name: yup.string().min(4).max(40).required(),
});

export default function CreateGameRoute() {
    const {register, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(schema)});
    const history = useHistory();

    const submit = handleSubmit(async data => {
        const game = await createGame(data.name);

        history.push("/dev/game/" + game.id);
    });

    return (
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
                <Card>
                    <Card.Body>
                        <Card.Title>Create a game</Card.Title>
                        <Form onSubmit={submit}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control {...register("name")} type="text" isInvalid={!!errors.name} />
                                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">Create</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}