import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { FormEvent, useCallback, useState } from "react";
import { ChatMessage } from "../api/websocket/ChatServerMessage";
import adjacentGroupBy from "../utils/adjacentGroupBy";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import cn from "classnames";
import { useAuthenticatedWebsocket } from "../api/apiClient";

function useChat(roomId: string) {
    const [connected, setConnected] = useState<boolean>(false);
    const [messages, setMessages] = useState<ChatMessage[]>([]);

    /**
     * Websocket related
     */
    const host = process.env.REACT_APP_WS_BASE_URL || `ws://${window.location.host}/ws`;
    const url = `${host}/chat/room/${roomId}`;

    const onOpen = useCallback(send => {
        send({
            type: "fetch-messages",
            count: 30
        });
    }, []);
    
    const onMessage = useCallback((message, send) => {
        if (message.type == "messages-fetched") {
            setConnected(true);
            setMessages(message.messages);
        } else if (message.type == "new-message") {
            setMessages([...messages, message.message]);
        }
    }, [messages]);

    const {send} = useAuthenticatedWebsocket(url, onOpen, onMessage);

    function sendMessage(text: string) {
        send({
            type: "message",
            text
        });
    }

    return {
        messages,
        connected,
        sendMessage,
    };
}

function toDateDisplay(message: ChatMessage) {
    const datetime = moment(message.created_at);
    return (
        <div className="ml-1">
            <small className="text-muted">{datetime.format("HH:mm")}</small>
        </div>
    );
}

export default function ChatPane({roomId}: any) {
    const {messages, sendMessage} = useChat(roomId);
    const [text, setText] = useState<string>("");
    const [open, setOpen] = useState<boolean>(true);

    function onSubmit(e: FormEvent) {
        if (text == "") {
            return;
        }
        sendMessage(text);
        setText("");

        e.preventDefault();
    }

    // Group messages by user
    const groupedMessages = adjacentGroupBy(messages, message => message.user);

    return (
        <ListGroup>
            <ListGroupItem>
                <h5 style={{marginBottom: 0}}>
                    Chat <FontAwesomeIcon className="float-right" icon={open ? faCaretDown : faCaretUp} onClick={() => setOpen(!open)} />
                </h5>
            </ListGroupItem>
            <ListGroupItem className={cn("flex-fill", {"d-none": !open})} style={{height: 330, overflowY: "auto"}}>
                {groupedMessages.map(([user, messages]) => (
                    <div key={messages[0].id}>
                        <b>{user.username}</b><br />
                        {messages.map(message => (
                            <div className="d-flex flex-row w-100" key={message.id}>
                                <div className="flex-fill">{message.text}</div>
                                {toDateDisplay(message)}
                            </div>
                        ))}
                    </div>
                ))}
            </ListGroupItem>
            <ListGroupItem className={cn({"d-none": !open})}>
                <Form onSubmit={onSubmit}>
                    <Form.Row>
                        <Col>
                            <Form.Control value={text} onChange={e => setText(e.target.value)} />
                        </Col>
                        <Col xs="auto">
                            <Button type="submit">Send</Button>
                        </Col>
                    </Form.Row>
                </Form>
            </ListGroupItem>
        </ListGroup>
    );
}