export interface User {
    id: string,
    username: string,
}

export enum MatchStatus {
    IN_LOBBY = "IN_LOBBY",
    STARTED = "STARTED",
    CLOSED = "CLOSED",
    CANCELLED = "CANCELLED",
    FINISHED = "FINISHED"
}

export interface Game {
    id: string,
    name: string,
    url: string,
    allow_super_integration: boolean,
    logo_url: string,
}

export interface DevGame {
    id: string,
    name: string,
    url: string,
    allow_super_integration: boolean,
    logo_url: string,
    token: string,
}

export interface Room {
    id: string,
    name: string,
    public: boolean,
    users: any[]
}

export interface Player {
    metadata: {[key: string]: string},
}

export interface Match {
    id: string,
    name: string,
    game: Game,
    status: MatchStatus,
    rooms: Room[],
    player_count: number,
    max_players: number,
    metadata: {[key: string]: string},
    // This will contain either one element or zero, depending
    // on whether the connect user is a player in this game
    player: Player[],
}
