import _ from "lodash";

export default function adjacentGroupBy<E, K>(collection: E[], getKey: (element: E) => K): [K, E[]][] {
    return collection.reduce((accumulator, element) => {
        const key = getKey(element);

        if (accumulator.length == 0) {
            return [[key, [element]]];
        }

        const lastEntry = accumulator[accumulator.length - 1];
        const [lastKey, lastGroup] = lastEntry;

        if (_.isEqual(lastKey, key)) {
            lastGroup.push(element);
        } else {
            accumulator.push([key, [element]]);
        }

        return accumulator;
    }, [] as [K, E[]][]);
}