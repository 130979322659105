import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import { Match } from "../api/models";
import { getMatch } from "../api/requests";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChatPane from "../components/ChatPane";
import { useContext } from "react";
import { AuthenticationContext } from "../stores/authentication";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface PlayParams {
    gameId: string;
    id: string;
}

export default function PlayRoute() {
    const {gameId, id} = useParams<PlayParams>();

    const [match, setMatch] = useState<Match | null>(null);
    const history = useHistory();

    const {id: userId} = useContext(AuthenticationContext);

    async function initMatch() {
        const matches = await getMatch(gameId, id);

        setMatch(matches);
    }

    useEffect(() => {
        initMatch();
    }, []);

    function goBack() {
        if (history.length > 0) {
            history.goBack();
        } else {
            if (match) {
                history.push("/game/" + match.game.id);
            }
        }
    }

    return (match &&
        <div className="d-flex flex-column h-100">
            <Navbar>
                <Navbar.Text className="mr-3">
                    <Button onClick={goBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                </Navbar.Text>
                <Navbar.Text style={{fontSize: "1.2rem"}}>{match.name}</Navbar.Text>
            </Navbar>
            <div className="flex-grow-1">
                <div style={{position: "absolute", bottom: 0, right: 15}}>
                    <Row>
                        <Col xs="auto" style={{width: 370}}>
                            <ChatPane roomId={match.rooms[0].id} />
                        </Col>
                    </Row>
                </div>
                <iframe style={{border: "none"}} className="d-block flex-grow-1" width="100%" height="100%" title="Play game" src={`${match.game.url}#${userId}.${id}.${userId}${id}`} />
            </div>
        </div>
    );
}

