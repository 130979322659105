import { Game } from "../api/models";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GameCard from "../components/GameCard";

export interface GamesRowProps {
    games: Game[] | null;
    link?: (game: Game) => string;
}

export default function GamesRow({games, link}: GamesRowProps) {
    if (!link) {
        link = game => "/game/" + game.id;
    }

    return (
        <Row>
            {games && (
                games.map(game => (
                    <Col xs={12} sm={3} key={game.id}>
                        {/* @ts-expect-error link is not null because it was defined earlier */}
                        <GameCard game={game} link={link(game)} />
                    </Col>
                ))
            )}
        </Row>
    );
}