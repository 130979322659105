import { useContext, useRef } from 'react';
import { AuthenticationContext, AuthenticationStatus } from './stores/authentication';
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Spinner from "react-bootstrap/Spinner";
import {Switch, Route} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import HomeRoute from "./routes/HomeRoute";
import GamesRoute from './routes/GamesRoute';
import GameRoute from './routes/GameRoute';
import PlayRoute from './routes/PlayRoute';
import SignInRoute from './routes/SignInRoute';
import RegisterRoute from './routes/RegisterRoute';
import ProtectedRoute from "./routes/utils/ProtectedRoute";
import {useHistory} from "react-router";
import NotificationSystem from './components/NotificationsSystem';
import { NotificationContext } from './stores/notification';
import DeveloperRoute from './routes/DeveloperRoute';
import DevGameRoute from './routes/DevGameRoute';
import CreateGameRoute from './routes/CreateGameRoute';
import LoadingBar from "react-top-loading-bar";
import CompleteRegistrationRoute from './routes/CompleteRegistrationRoute';


export default function App() {
    const {status, username, logOut} = useContext(AuthenticationContext);
    const history = useHistory();
    const {addNotification} = useContext(NotificationContext);

    async function onLogOutClick() {
        await logOut();

        history.push("/");

        addNotification("Successfuly logged out", "success");
    }

    const ref = useRef<any>(null);

    return (
        status == AuthenticationStatus.CHECKING ? (
            <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner animation="grow" variant="primary" />
            </div>
        ) : (
            <>
                <NotificationSystem/>
                <LoadingBar height={3} waitingTime={300} color={"#007bff"} ref={ref} />
                <Switch>
                    <Route path="/play/:gameId/:id" children={<PlayRoute />} />
                    <Route path="*">
                        <Navbar bg="dark">
                            <LinkContainer to="/">
                                <Navbar.Brand>AirMeeple <Badge variant="secondary">Beta</Badge></Navbar.Brand>
                            </LinkContainer>
                            <Navbar.Collapse className="justify-content-end">
                                {status == AuthenticationStatus.AUTHENTICATED ? (
                                    <>
                                        <LinkContainer to="/games">
                                            <Nav.Link>Play</Nav.Link>
                                        </LinkContainer>
                                        <Nav>
                                            <NavDropdown title={username} id="basic-nav-dropdown">
                                                <NavDropdown.Item>Profile</NavDropdown.Item>
                                                <LinkContainer isActive={() => false} to="/dev"><NavDropdown.Item>Dev</NavDropdown.Item></LinkContainer>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item onClick={onLogOutClick}>Log out</NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav>
                                    </>
                                ) : (
                                    <>
                                        <LinkContainer to="/join">
                                            <Button>Register</Button>
                                        </LinkContainer>
                                        <LinkContainer to="/sign_in">
                                            <Button className="ml-2">Sign in</Button>
                                        </LinkContainer>
                                    </>
                                )}
                            </Navbar.Collapse>
                        </Navbar>
                        <Container className="mt-5">
                            <Switch>
                                <Route exact path="/" component={HomeRoute} />
                                <ProtectedRoute mustBeAuthenticated={true} path="/games" component={GamesRoute} />
                                <ProtectedRoute mustBeAuthenticated={true} path="/game/:id" component={GameRoute} />
                                <ProtectedRoute mustBeAuthenticated={false} path="/join" component={RegisterRoute} />
                                <ProtectedRoute mustBeAuthenticated={false} path="/complete_registration" component={CompleteRegistrationRoute} />
                                <ProtectedRoute mustBeAuthenticated={false} path="/sign_in" component={SignInRoute} />
                                <ProtectedRoute mustBeAuthenticated={true} path="/dev/game/create" component={CreateGameRoute} />
                                <ProtectedRoute mustBeAuthenticated={true} path="/dev/game/:id" component={DevGameRoute} />
                                <ProtectedRoute mustBeAuthenticated={true} path="/dev" component={DeveloperRoute} />
                            </Switch>
                        </Container>
                    </Route>
                </Switch>
            </>
        )
    );
}
