import axios from "axios";
import getCookie from "../utils/getCookie";
import {useCallback, useEffect, useRef} from "react";

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "/api",
    headers: {
        common: {
            "X-CSRFToken": getCookie("csrftoken")
        }
    }
});

apiClient.interceptors.request.use(async config => {
    if (getToken) {
        // Calling `getToken` will refresh the Firebase token
        // if it is expired, thus why it needs to be called
        // at every request.
        const idToken = await getToken();

        return {
            ...config,
            headers: {
                ...config.headers,
                "Authorization": `Bearer ${idToken}`,
            }
        };
    }

    return config;
});

let getToken: (() => Promise<string>) | null = null;

export async function setAuthorizationToken(givenGetToken: (() => Promise<string>) | null) {
    getToken = givenGetToken;
}

export function useAuthenticatedWebsocket(
    url: string,
    onOpen: (send: (message: object) => void) => void,
    onMessage: (message: object, send: (message: object) => void) => void
) {
    const ws = useRef<WebSocket | null>(null);

    function send(message: object) {
        const data = JSON.stringify(message);

        ws.current!.send(data);
    }

    const onOpenInternal = useCallback(async () => {
        if (!getToken) {
            console.error("websocket was opened, but user is not authenticated");
            return;
        }

        const idToken = await getToken();
        send({type: "auth", token: idToken});
        onOpen(send);
    }, [onOpen]);

    const onMessageInternal = useCallback(async (ev: MessageEvent) => {
        const message = JSON.parse(ev.data);
        onMessage(message, send);
    }, [onMessage]);

    useEffect(() => {
        ws.current = new WebSocket(url);
    }, []);

    useEffect(() => {
        ws.current!.onopen = onOpenInternal;
        ws.current!.onmessage = onMessageInternal;
    }, [onOpen, onMessage]);

    return {
        send
    };
}
