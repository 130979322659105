import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AuthenticationContext } from "../stores/authentication";

const schema = yup.object().shape({
    username: yup.string().min(3).max(30).required(),
});

export default function CompleteRegistrationRoute() {
    const {register, handleSubmit, formState: {errors}, setError} = useForm({resolver: yupResolver(schema)});

    const {completeRegistration} = useContext(AuthenticationContext);
    const history = useHistory();
    
    const submit = handleSubmit(async data => {
        try {
            await completeRegistration(data.username);

            history.push("/");
        } catch (e) {
            if (e.response.status == 400 && e.response) {
                for (const key in e.response.data) {
                    setError(key, {message: e.response.data[key][0]})
                }
            }
        }
    });

    return (
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
                <Card>
                    <Card.Body>
                        <Card.Title>Complete Registration</Card.Title>
                        <Form onSubmit={submit}>
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control {...register("username")} type="text" isInvalid={!!errors.username} />
                                <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">Register</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
