import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {Game, Match, MatchStatus} from "../api/models";
import { createMatch, getGame, getMatches } from "../api/requests";
import {LinkContainer} from "react-router-bootstrap";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {useForm} from "react-hook-form";
import {useHistory} from "react-router";

interface GameParams {
    id: string;
}

const schema = yup.object().shape({
    name: yup.string().min(3).max(30).required(),
});

export default function GameRoute() {
    const {id} = useParams<GameParams>();

    const history = useHistory();

    const [game, setGame] = useState<Game | null>(null);
    const [matches, setMatches] = useState<Match[] | null>(null);

    async function initGame() {
        const game = await getGame(id);

        setGame(game);
    }
    async function initMatches() {
        const matches = await getMatches(id);

        setMatches(matches);
    }
    useEffect(() => {
        initGame();
        initMatches();
    }, []);

    function getStatusBadge(match: Match) {
        if (match.status == MatchStatus.IN_LOBBY) {
            return <Badge variant="success">OPEN</Badge>;
        } else if (match.status == MatchStatus.STARTED) {
            return <Badge variant="warning">ONGOING</Badge>;
        } else if (match.status == MatchStatus.CANCELLED) {
            return <Badge variant="secondary">CANCELLED</Badge>;
        } else if (match.status == MatchStatus.FINISHED) {
            return <Badge variant="info">FINISHED</Badge>;
        } else if (match.status == MatchStatus.CLOSED) {
            return <Badge variant="secondary">CLOSED</Badge>;
        } else {
            return <Badge variant="secondary">UNKNOWN</Badge>;
        }
    }

    /**
     * Setup the "Create Match" form
     */
    const {register, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(schema)});

    const submit = handleSubmit(async data => {
        try {
            const match = await createMatch(id, data.name);

            history.push(`/play/${id}/${match.id}`);
        } catch (e) {
            console.log(e);
        }
    });

    return (
        <>
            {game && (
                <h2>{game.name}</h2>
            )}
            <Card className="mt-4">
                <Card.Body>
                    <Card.Title>Matches</Card.Title>
                    <Table hover size="sm" className="mt-3">
                        <thead>
                            <tr>
                                <th style={{width: "5rem"}}></th>
                                <th>Name</th>
                                <th style={{width: "3rem"}} className="text-center">Players</th>
                                <th style={{width: "9rem"}}></th>
                            </tr>
                        </thead>    
                        <tbody>
                            {matches ? (
                                matches.length > 0 ? (
                                    matches.map(match => (
                                        <tr key={match.id}>
                                            <td style={{textAlign: "center"}}>{getStatusBadge(match)}</td>
                                            <td>
                                                <span dangerouslySetInnerHTML={{ __html: match.game.allow_super_integration ? match.metadata.badges : "" }}></span>
                                                {" "}
                                                {match.player.length > 0 && (
                                                    <span dangerouslySetInnerHTML={{ __html: match.player[0].metadata.badges }}></span>
                                                )}
                                                {" "}
                                                {match.name}
                                            </td>
                                            <td className="text-center">{match.player_count}/{match.max_players}</td>
                                            <td style={{textAlign: "right"}}>
                                                <LinkContainer to={`/play/${id}/${match.id}`}><Button size="sm">Join</Button></LinkContainer>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="td-empty">No matches</td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td colSpan={4} className="td-empty"><Spinner animation="border" variant="primary" /></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Row>
                <Col xs={8}>
                    <Card className="mt-4">
                        <Card.Body>
                            <Card.Title>Create match</Card.Title>
                            <Form onSubmit={submit}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control {...register("name")} type="text" isInvalid={!!errors.name} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </Form.Group>
                                <Row className="justify-content-end">
                                    <Col xs="auto">
                                        <Button type="submit">Create</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}