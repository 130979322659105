import { useContext } from "react";
import { AuthenticationContext, AuthenticationStatus } from "../../stores/authentication";
import { Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";  

export default function ProtectedRoute({component: Component, mustBeAuthenticated, ...rest}: any) {
    const {status} = useContext(AuthenticationContext);
    const location = useLocation();

    let mustRedirect = false;
    if (mustBeAuthenticated === true && status != AuthenticationStatus.AUTHENTICATED) {
        mustRedirect = true;
        console.log("Redirecting because unauthenticated");
    } else if (mustBeAuthenticated === false && status != AuthenticationStatus.UNAUTHENTICATED) {
        console.log("Redirecting because authenticated");
        mustRedirect = true;
    }

    let urlToRedirect = "";
    if (mustRedirect) {
        if (mustBeAuthenticated) {
            urlToRedirect = "/sign_in?next=" + location.pathname;
        } else {
            urlToRedirect = "/";
        }
        console.log("Redirecting " + urlToRedirect);
    }

    return <Route {...rest} render={(props) => 
        !mustRedirect
        ? <Component {...props} />
        : <Redirect to={urlToRedirect} />}
    />;
}