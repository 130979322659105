import { FunctionComponent, useEffect, useState, createContext } from "react";

const TIME_KEEP_NOTIFICATION = 4 * 1000;

export interface Notification {
    id: string;
    message: string;
    type: string;
    createdTime: number;
    toFlushTime: number;
}

export interface NotificationState {
    notifications: Notification[];
}

export interface NotificationContextArgs extends NotificationState {
    addNotification: (message: string, type: string) => void;
}

// @ts-expect-error createContext without any argument is not defined, but does exist.
export const NotificationContext = createContext<NotificationContextArgs>(undefined);

export const NotificationProvider: FunctionComponent = ({children}) => {
    const [state, setState] = useState<NotificationState>({
        notifications: []
    });
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const {notifications} = state;

    function addNotification(message: string, type: string) {
        const notification = {
            id: Math.random().toString(36).substr(2, 9),
            message,
            type,
            createdTime: Date.now(),
            toFlushTime: Date.now() + TIME_KEEP_NOTIFICATION
        }
        console.log("Before add, length " + notifications.length);
        setState({notifications: [...notifications, notification]});
    }

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (notifications.length > 0) {
            const notification = notifications[0];
            const timeBeforeNextNotificationExpire = notification.toFlushTime - Date.now();
            setTimeoutId(setTimeout(() => {
                setState({notifications: notifications.slice(1)});
            }, timeBeforeNextNotificationExpire));
        }
    }, [notifications]);

    return <NotificationContext.Provider value={{...state, addNotification}}>{children}</NotificationContext.Provider>
}