import { useParams, useRouteMatch } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import {Switch, Route} from "react-router-dom";
import Card from "react-bootstrap/Card";
import {LinkContainer} from "react-router-bootstrap";
import { DevGame } from "../api/models";
import { useEffect, useState, useContext } from "react";
import { getDevGame, updateGame } from "../api/requests";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Button from "react-bootstrap/Button";
import { NotificationContext } from "../stores/notification";

interface DevGameParams {
    id: string;
}

const schema = yup.object().shape({
    url: yup.string().min(3).max(255),
    logo_url: yup.string().max(255),
});

export default function DevGameRoute() {
    const {id} = useParams<DevGameParams>();
    const {url} = useRouteMatch();
    const {addNotification} = useContext(NotificationContext);

    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const submit = handleSubmit(async data => {
        try {
            await updateGame(id, data.url, data.logo_url);

            addNotification("Game updated", "success");
        } catch (e) {
            if (e.code == "auth/email-already-in-use") {
                setError("email", {type: "email-in-use", message: "Email already in use"});
            }
        }
    });

    const [game, setGame] = useState<DevGame | null>(null);

    async function initGame() {
        const game = await getDevGame(id);

        setGame(game);

        setValue("logo_url", game.logo_url);
        setValue("url", game.url);
    }
    useEffect(() => {
        initGame();
    }, []);

    return (game &&
        <>
            <h2>{game.name}</h2>
            <Row>
                <Col xs={3}>
                    <ListGroup>
                        <LinkContainer exact to={url} ><ListGroup.Item action>Settings</ListGroup.Item></LinkContainer>
                        <LinkContainer to={url + "/matches"} ><ListGroup.Item action>Matches</ListGroup.Item></LinkContainer>
                    </ListGroup>
                </Col>
                <Col xs={9}>
                    <Switch>
                        <Route exact path={url}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Settings</Card.Title>
                                    <Form onSubmit={submit}>
                                        <Form.Group>
                                            <Form.Label>Url</Form.Label>
                                            <Form.Control {...register("url")} type="text" isInvalid={!!errors.url} />
                                            <Form.Control.Feedback type="invalid">{errors.url?.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Logo url</Form.Label>
                                            <Form.Control {...register("logo_url")} type="text" isInvalid={!!errors.logo_url} />
                                            <Form.Control.Feedback type="invalid">{errors.logo_url?.message}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Token Username</Form.Label>
                                            <Form.Control type="text" value={game.id} readOnly />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Token Password</Form.Label>
                                            <Form.Control type="text" value={game.token} readOnly />
                                        </Form.Group>
                                        <Button variant="primary" type="submit">Save</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Route>
                        <Route path={url + "/matches"}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Matches</Card.Title>
                                    Here you'll be able to see the matches of your game, and administer them
                                </Card.Body>
                            </Card>
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </>
    );
}