import { createContext, FunctionComponent, useEffect, useRef, useState } from "react";
import { setAuthorizationToken } from "../api/apiClient";
import { createUserMe, getUserMe } from "../api/requests";
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithRedirect, GoogleAuthProvider, User} from "firebase/auth";
import { useHistory } from "react-router-dom";

export enum AuthenticationStatus {
    CHECKING,
    AUTHENTICATED,
    UNAUTHENTICATED,
}

export interface AuthenticationState {
    id: string,
    username: string,
    status: AuthenticationStatus,
}

export interface AuthenticationContextArgs extends AuthenticationState {
    logOut: () => void,
    register: (username: string, email: string, password: string) => Promise<void>,
    signIn: (email: string, password: string) => Promise<void>,
    signInWithGoogle: () => void,
    completeRegistration: (username: string) => void,
}

// @ts-expect-error createContext without any argument is not defined, but does exist.
export const AuthenticationContext = createContext<AuthenticationContextArgs>(undefined);

export const AuthenticationProvider: FunctionComponent = ({children}) => {
    const [state, setState] = useState<AuthenticationState>({
        id: "",
        username: "",
        status: AuthenticationStatus.CHECKING
    });
    const history = useHistory();
    const userRef = useRef<User | null>(null);

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            // Only use this callack to set-up the first data of the page load.
            // After, all state will be setup manually without this callback
            if (state.status == AuthenticationStatus.CHECKING) {
                if (user) {
                    userRef.current = user;
                    postFirebaseSignIn(user);
                } else {
                    setState({
                        ...state,
                        status: AuthenticationStatus.UNAUTHENTICATED
                    });
                }
            }
        });

        return unsubscribe;
    }, [userRef, state]);

    async function signIn(email: string, password: string) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        await postFirebaseSignIn(userCredential.user);
    }

    async function postFirebaseSignIn(user: User) {
        userRef.current = user;
        
        setAuthorizationToken(() => user.getIdToken());

        try {
            const {id, username} = await getUserMe();
    
            setState({
                status: AuthenticationStatus.AUTHENTICATED,
                username,
                id
            });
        } catch (e) {
            // If Firebase is logged in, but there's no user server-side,
            // then trigger the complete registration flow
            if (e.response.data.status == "no-user") {
                setState({
                    ...state,
                    status: AuthenticationStatus.UNAUTHENTICATED,
                });

                history.push("/complete_registration");
                return;
            }

            throw e;
        }
    }

    async function logOut() {
        await auth.signOut();

        setAuthorizationToken(null);
        userRef.current = null;

        setState({
            ...state,
            status: AuthenticationStatus.UNAUTHENTICATED
        });
    }

    async function register(username: string, email: string, password: string) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        userRef.current = userCredential.user;

        completeRegistration(username);
    }

    async function signInWithGoogle() {
        const provider = new GoogleAuthProvider();

        signInWithRedirect(auth, provider);
    }

    async function completeRegistration(username: string) {
        const idToken = await userRef.current!.getIdToken();

        await createUserMe(idToken, username);

        await postFirebaseSignIn(userRef.current!);
    }

    return (
        <AuthenticationContext.Provider value={{...state, logOut, signIn, register, signInWithGoogle, completeRegistration}}>
            {children}
        </AuthenticationContext.Provider>
    );
};