import { useEffect, useState } from "react";
import { Game } from "../api/models";
import { getGames } from "../api/requests";
import GamesRow from "../components/GamesRow";

export default function GamesRoute() {
    const [games, setGames] = useState<Game[] | null>(null);

    async function init() {
        const games = await getGames();

        setGames(games);
    }
    useEffect(() => { init() }, []);

    return (
        <>
            <h2>Games</h2>
            <GamesRow games={games} />
        </>
    );
}